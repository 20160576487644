// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profil-js": () => import("./../../../src/pages/profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-pages-seances-js": () => import("./../../../src/pages/seances.js" /* webpackChunkName: "component---src-pages-seances-js" */),
  "component---src-pages-tarifs-js": () => import("./../../../src/pages/tarifs.js" /* webpackChunkName: "component---src-pages-tarifs-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */)
}

